export function makeMap(str, expectsLowerCase) {
    const map = Object.create(null)
    const list = str.split(',')
    for (let i = 0; i < list.length; i++) {
        map[list[i]] = true
    }
    return expectsLowerCase
        ? val => map[val.toLowerCase()]
        : val => map[val]
}

/**
 * num 小于0，左缩进num*2个空格； 大于0，右缩进num*2个空格。
 * @param {string} str 代码
 * @param {number} num 缩进次数
 * @param {number} len 【可选】缩进单位，空格数
 */
export function indent(str, num, len = 2) {
    if (num === 0) return str
    const isLeft = num < 0;
    const result = [];
    let reg;
    let
        spaces = ''
    if (isLeft) {
        num *= -1
        reg = new RegExp(`(^\\s{0,${num * len}})`, 'g')
    } else {
        for (let i = 0; i < num * len; i++) spaces += ' '
    }

    str.split('\n').forEach(line => {
        line = isLeft ? line.replace(reg, '') : spaces + line
        result.push(line)
    })
    return result.join('\n')
}

// 首字母大小
export function titleCase(str) {
    return str.replace(/( |^)[a-z]/g, L => L.toUpperCase())
}

// 下划转驼峰
export function camelCase(str) {
    return str.replace(/-[a-z]/g, str1 => str1.substr(-1).toUpperCase())
}

export function isNumberStr(str) {
    return /^[+-]?(0|([1-9]\d*))(\.\d+)?$/g.test(str)
}

export function isNotEmpty(obj) {
    return !(typeof obj == "undefined" || obj == null || obj === "");
}

export function isEmpty(obj) {
    return typeof obj == "undefined" || obj == null || obj === "";
}

export const beautifierConf = {
    html: {
        indent_size: '2',
        indent_char: ' ',
        max_preserve_newlines: '-1',
        preserve_newlines: false,
        keep_array_indentation: false,
        break_chained_methods: false,
        indent_scripts: 'separate',
        brace_style: 'end-expand',
        space_before_conditional: true,
        unescape_strings: false,
        jslint_happy: false,
        end_with_newline: true,
        wrap_line_length: '110',
        indent_inner_html: true,
        comma_first: false,
        e4x: true,
        indent_empty_lines: true
    },
    js: {
        indent_size: '2',
        indent_char: ' ',
        max_preserve_newlines: '-1',
        preserve_newlines: false,
        keep_array_indentation: false,
        break_chained_methods: false,
        indent_scripts: 'normal',
        brace_style: 'end-expand',
        space_before_conditional: true,
        unescape_strings: false,
        jslint_happy: true,
        end_with_newline: true,
        wrap_line_length: '110',
        indent_inner_html: true,
        comma_first: false,
        e4x: true,
        indent_empty_lines: true
    }
}

function stringify(obj) {
    return JSON.stringify(obj, (key, val) => {
        if (typeof val === 'function') {
            return `${val}`
        }
        return val
    })
}

function parse(str) {
    return JSON.parse(str, (k, v) => {
        if (v !== null && v.indexOf && v.indexOf('function') > -1) {
            return eval(`(${v})`)
        }
        return v
    })
}

export function jsonClone(obj) {
    return parse(stringify(obj));
}

export function isLayout(obj) {
    return obj.compType === 'row';
}

export function isTable(obj) {
    return obj.compType === 'table';
}

export function inTable(obj) {
    return obj.col && obj.row;
}

export const isAttr = makeMap(
    'accept,accept-charset,accesskey,action,align,alt,async,autocomplete,'
    + 'autofocus,autoplay,autosave,bgcolor,border,buffered,challenge,charset,'
    + 'checked,cite,class,code,codebase,color,cols,colspan,content,http-equiv,'
    + 'name,contenteditable,contextmenu,controls,coords,data,datetime,default,'
    + 'defer,dir,dirname,disabled,download,draggable,dropzone,enctype,method,for,'
    + 'form,formaction,headers,height,hidden,high,href,hreflang,http-equiv,'
    + 'icon,id,ismap,itemprop,keytype,kind,label,lang,language,list,loop,low,'
    + 'manifest,max,maxlength,media,method,GET,POST,min,multiple,email,file,'
    + 'muted,name,novalidate,open,optimum,pattern,ping,placeholder,poster,'
    + 'preload,radiogroup,readonly,rel,required,reversed,rows,rowspan,sandbox,'
    + 'scope,scoped,seamless,selected,shape,size,type,text,password,sizes,span,'
    + 'spellcheck,src,srcdoc,srclang,srcset,start,step,style,summary,tabindex,'
    + 'target,title,type,usemap,value,width,wrap,classStyle,cssStyle'
)
// 获取Class 和 css
export function getClassCss(code) {
    let data = {}
    if (code && code.stylesheet.rules.length > 0){
        for (let i = 0; i < code.stylesheet.rules.length; i++) {
            let rule = code.stylesheet.rules[i]
            let css = ""
            for(let item of rule.declarations){
                css = css+item.property+":"+item.value +";"
            }
            data[rule.selectors[0]]= css
        }
        return data
    }
    return  null
}

// 移除样式
export function removeStyle(selector, title) {
    if (title === undefined) {
        title = 'sheet'
    }
    // 获取页面中的style样式表，并转成数组
    let styleSheets = Array.from(document.styleSheets)
    // 找到对应的样式表
    let styleSheet = styleSheets.reduce(function (value, item) {
        if (item.title === title) {
            // 如果找到title属性为给定的title的样式表，那么就返回
            return item
        } else {
            // 找不到就返回null
            return value
        }
    }, null)
    if (styleSheet) {
        for (let i = 0; i < styleSheet.rules.length; i++) {
            let rule = styleSheet.rules[0]
            if (rule.selectorText == selector) {
                styleSheet.deleteRule(i)
                i--;
            }
        }
    }
}

// 封装插入样式的函数
export function addStyle(selector, style, title) {
    if (title === undefined) {
        title = 'sheet'
    }
    // 首先要找到title为给定的title的样式表

    // 获取页面中的style样式表，并转成数组
    let styleSheets = Array.from(document.styleSheets)
    // 找到对应的样式表
    let styleSheet = styleSheets.reduce(function (value, item) {
        if (item.title === title) {
            // 如果找到title属性为给定的title的样式表，那么就返回
            return item
        } else {
            // 找不到就返回null
            return value
        }
    }, null)
    // 如果没找到 就新建一个
    if (!styleSheet) {
        // 创建style标签
        let sty = document.createElement('style')
        // 添加title属性
        sty.title = title
        document.head.appendChild(sty)
        // 获取到新添加的这个样式表，要往里添加样式的
        styleSheet = document.styleSheets[document.styleSheets.length - 1]
    }
    // 样式添加
    styleSheet.addRule(selector, style, styleSheet.cssRules.length)
}
