import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'//引入axios

import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'

import formDesigner from '@/components/index'
import '@/assets/iconfont/iconfont.js'

Vue.use(formDesigner)
Vue.use(ElementUI)
Vue.config.productionTip = false
Vue.prototype.$axios = axios;//把axios挂载到vue上

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
