import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'home',
        component: HomeView
    },
    {
        path: '/about',
        name: 'about',
        component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
    },
    {
        path: '/ddDesign',
        name: 'ddDesign',
        component: () => import(/* webpackChunkName: "about" */ '../components/DingDesigner/index.vue')
    },
    {
        path: '/mainDesign',
        name: 'mainDesign',
        component: () => import(/* webpackChunkName: "about" */ '../components/ProcessDesigner/index2.vue')
    },
    {
        path: '/formDesign',
        name: 'formDesign',
        component: () => import('@/views/formdesigner/index.vue'),
        meta: {title: '表单设计', icon: ''}
    },
    {
        path: '/processReview',
        name: 'processReview',
        component: () => import(/* webpackChunkName: "about" */ '../components/ProcessViewer/index2.vue')
    },
    {
        path: '/formBuilder',
        name: 'formBuilder',
        component: () => import(/* webpackChunkName: "about" */ '../components/formdesigner/components/formBuilder.vue')
    },

]

const router = new VueRouter({
    routes
})

export default router
