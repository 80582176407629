import { render, staticRenderFns } from "./fancyDynamicTable.vue?vue&type=template&id=2b9056ee&scoped=true"
import script from "./fancyDynamicTable.vue?vue&type=script&lang=js"
export * from "./fancyDynamicTable.vue?vue&type=script&lang=js"
import style0 from "./fancyDynamicTable.vue?vue&type=style&index=0&id=2b9056ee&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2b9056ee",
  null
  
)

export default component.exports